import http from "../http";

export const getTenantList = params => {
  return http({
    method: "get",
    url: "/api/tenant/gettenants",
    params
  });
};

export const delTenant = data => {
  return http({
    method: "delete",
    url: "/api/tenant/deletetenant",
    data
  });
};

export const createTenant = data => {
  return http({
    method: "post",
    url: "/api/tenant/createtenant",
    data
  });
};

export const updateTenant = data => {
  return http({
    method: "put",
    url: "/api/tenant/updatetenant",
    data
  });
};

export const getPaymentClasses = params => {
  return http({
    method: "get",
    url: "/api/tenant/getpaymentclasses",
    params
  });
};

export const delPaymentClass = data => {
  return http({
    method: "delete",
    url: "/api/tenant/deletepaymentclass",
    data
  });
};

export const createPaymentClass = data => {
  return http({
    method: "post",
    url: "/api/tenant/createpaymentclass",
    data
  });
};

export const updatePaymentClass = data => {
  return http({
    method: "put",
    url: "/api/tenant/updatepaymentclass",
    data
  });
};

export const getPaymentTypes = params => {
  return http({
    method: "get",
    url: "/api/tenant/getpaymenttypes",
    params
  });
};

export const delPaymentType = data => {
  return http({
    method: "delete",
    url: "/api/tenant/deletepaymenttype",
    data
  });
};

export const createPaymentType = data => {
  return http({
    method: "post",
    url: "/api/tenant/createpaymenttype",
    data
  });
};

export const updatePaymentType = data => {
  return http({
    method: "put",
    url: "/api/tenant/updatepaymenttype",
    data
  });
};

export const getTradeTypes = params => {
  return http({
    method: "get",
    url: "/api/tenant/gettradetypes",
    params
  });
};

export const delTradeType = data => {
  return http({
    method: "delete",
    url: "/api/tenant/deletetradetype",
    data
  });
};

export const createTradeType = data => {
  return http({
    method: "post",
    url: "/api/tenant/createtradetype",
    data
  });
};

export const updateTradeType = data => {
  return http({
    method: "put",
    url: "/api/tenant/updatetradetype",
    data
  });
};

export const uploadPaymentImage = data => {
  return http({
    method: "post",
    url: `/api/paymentdisplaycard/uploadpaymentpaperimage`,
    data,
    headers: {
      "Content-Type": "multipart/form-data;"
    }
  });
};

export const getPaymentpapers = params => {
  return http({
    method: "get",
    url: "/api/paymentdisplaycard/getpaymentpapers",
    params
  });
};

export const delPaymentpaper = data => {
  return http({
    method: "delete",
    url: "/api/paymentdisplaycard/deletepaymentpaper",
    data
  });
};

export const createPaymentpaper = data => {
  return http({
    method: "post",
    url: "/api/paymentdisplaycard/createpaymentpaper",
    data
  });
};

export const updatePaymentpaper = data => {
  return http({
    method: "put",
    url: "/api/paymentdisplaycard/updatepaymentpaper",
    data
  });
};
