<template>
  <div>
    <div class="setting-nav-title">
      {{ $t("tradeTypeManage") }}
      <!-- <el-button class="confirm pull-right" @click="applyNewPayment">{{ $t("newpayment") }}</el-button> -->
    </div>
    <div style="margin-bottom: 20px;">
      <el-button @click="createDialog = true">createPaymentType</el-button>
    </div>
    <div class="payment-container" v-loading="loading">
      <nodata v-if="paymentTypeList.length === 0" />
      <el-table v-else :data="paymentTypeList">
        <el-table-column prop="id" label="id"></el-table-column>
        <el-table-column prop="displayName" label="displayName"></el-table-column>
        <!-- <el-table-column prop="englishName" label="englishName"></el-table-column> -->
        <el-table-column prop="tenant" label="tenant"></el-table-column>
        <el-table-column prop="tenantId" label="tenantId"></el-table-column>
        <el-table-column prop="paymentClass" label="paymentClass"></el-table-column>
        <el-table-column prop="paymentClassId" label="paymentClassId"></el-table-column>
        <el-table-column prop="paymentType" label="paymentType"></el-table-column>
        <!-- <el-table-column prop="color" label="color"></el-table-column> -->
        <el-table-column prop="imgUrl" label="imgUrl">
          <template slot-scope="scope">
            <img :src="scope.row.imgUrl" style="width: 50px;height: 50px;" />
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="" width="180">
          <template slot-scope="scope">
            <el-button @click="ePaymentType(scope.row)" type="text" size="small">EDIT</el-button>
            <el-button @click="dPaymentType(scope.row)" type="text" size="small">DELETE</el-button>
            <!-- <el-button @click="uPaymentType(scope.row)" type="text" size="small">UPLOAD</el-button> -->
            <el-upload
              class="upload-demo"
              action="https://jsonplaceholder.typicode.com/posts/"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              :on-exceed="handleExceed"
              :file-list="fileList"
              :http-request="uPaymentType"
              :show-file-list="false"
              :data="scope.row"
            >
              <el-button size="small" type="text">UPLOAD</el-button>
            </el-upload>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="" :visible.sync="createDialog" v-loading="loading" @close="handelDialogClose">
      <el-form :model="form" ref="form">
        <el-form-item label="tenant" prop="tenant" required>
          <el-input v-model="form.tenant"></el-input>
        </el-form-item>
        <el-form-item label="tenantId" prop="tenantId" required>
          <el-input v-model="form.tenantId"></el-input>
        </el-form-item>
        <el-form-item label="paymentType" prop="paymentType" required>
          <el-input v-model="form.paymentType"></el-input>
        </el-form-item>

        <el-form-item label="paymentClass" prop="paymentClass" required>
          <el-input v-model="form.paymentClass"></el-input>
        </el-form-item>
        <el-form-item label="paymentClassId" prop="paymentClassId" required>
          <el-input v-model="form.paymentClassId"></el-input>
        </el-form-item>
        <el-form-item label="displayName" prop="displayName" required>
          <el-input v-model="form.displayName"></el-input>
        </el-form-item>
        <!-- <el-form-item label="englishName" prop="englishName" required>
          <el-input v-model="form.englishName"></el-input>
        </el-form-item>
        <el-form-item label="color" prop="color" required>
          <el-input v-model="form.color"></el-input>
        </el-form-item>
        <el-form-item label="creditTouch" prop="creditTouch" required>
          <el-switch v-model="form.creditTouch"></el-switch>
        </el-form-item> -->
        <el-button @click="cPaymentType()">Submit</el-button>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import paymentusing from "@/assets/using.png";
import paymentchecking from "@/assets/checking.png";
import paymentappling from "@/assets/appling.png";
import paymentfailed from "@/assets/failed.png";
import {
  getPaymentpapers,
  createPaymentpaper,
  delPaymentpaper,
  updatePaymentpaper,
  uploadPaymentImage
} from "api/tradetype";
import { getBigPayLogoByTradeTypeName } from "utils/tradeTypeLogo";
export default {
  data() {
    return {
      loading: false,
      createDialog: false,
      paymentusing,
      paymentchecking,
      paymentappling,
      paymentfailed,
      paylist: [],
      tenantList: [],
      paymentClassList: [],
      paymentTypeList: [],
      fileList: [],
      form: {
        id: 0,
        tenantId: 0,
        tenant: "string",
        paymentType: "string",
        paymentClassId: 0,
        paymentClass: "string",
        displayName: "string"
        // englishName: "string",
        // color: "string",
        // creditTouch: false
      }
    };
  },
  mounted() {
    this.gPaymentTypes();
    // let pc = getPaymentClasses().then(res => {
    //   console.log(res);
    //   if (res.statusCode == 200) {
    //     this.paymentClassList = res.result;
    //   }
    // });
    // let pt = getPaymentTypes().then(res => {
    //   console.log(res);
    //   if (res.statusCode == 200) {
    //     this.paymentTypeList = res.result;
    //   }
    // });
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handelDialogClose() {
      this.form = {
        id: 0,
        tenantId: 0,
        tenant: "string",
        paymentType: "string",
        paymentClassId: 0,
        paymentClass: "string",
        displayName: "string"
        // englishName: "string",
        // color: "string",
        // creditTouch: false
      };
      this.$refs.form.resetFields();
    },
    uPaymentType(row) {
      let data = new FormData();
      data.append("File", row.file);
      data.append("PaymentTypeId", row.data.id);
      data.append("TenantId", row.data.tenantId);

      console.log(data);
      uploadPaymentImage(data).then(res => {
        console.log(res);
        if (res.statusCode == 200) {
          this.gPaymentTypes();
          this.$message.success(this.$t("success"));
        }
      });
    },
    gPaymentTypes() {
      return getPaymentpapers({ pageSize: 9999 }).then(res => {
        console.log(res);
        if (res.statusCode == 200) {
          this.paymentTypeList = res.result;
        }
      });
    },
    cPaymentType() {
      this.$refs.form.validate(valid => {
        console.log(valid);
        if (valid) {
          this.loading = true;
          let xhr;

          if (this.form.id > 0) {
            xhr = updatePaymentpaper(this.form);
          } else {
            xhr = createPaymentpaper(this.form);
          }

          xhr
            .then(res => {
              this.loading = false;
              console.log(res);
              if (res.statusCode == 200) {
                // this.tenantList = res.result;
                this.createDialog = false;
                this.gPaymentTypes();
              }
            })
            .catch(err => {
              this.loading = false;
            });
        }
      });
    },
    dPaymentType(row) {
      this.$confirm("Confirm?", "warning", {
        type: "warning"
      })
        .then(() => {
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // });
          this.loading = true;

          delPaymentpaper({ id: row.id })
            .then(res => {
              this.loading = false;
              console.log(res);
              if (res.statusCode == 200) {
                this.gPaymentTypes().then(res => {
                  console.log(res);
                  this.loading = false;
                });
              }
            })
            .catch(err => {
              this.loading = false;
            });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    ePaymentType(row) {
      this.form = JSON.parse(JSON.stringify(row));
      this.createDialog = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.notification-container {
  margin: 16px 0;
}

.payment-container {
  background: #fffffe;
  box-shadow: 0 2px 12px 0 rgba(103, 95, 119, 0.1);
  border-radius: 8px;
  padding: 0 24px;
}

.payment-item {
  @include flex();

  padding: 16px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  &:last-child {
    border: none;
  }

  .payment-img-container {
    width: 122px;
    height: 80px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    position: relative;
    border-radius: 8px;

    & > img {
      width: 90px;
      height: 54px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .payment-content {
    flex: 1;
    padding: 0 30px;

    .payment-name {
      font-size: 16px;
      font-weight: 700;
      line-height: 23px;
    }

    .payment-rate {
      font-size: 12px;
    }
  }

  .payment-status {
    line-height: 24px;

    .payment-status-image {
      width: 20px;
      height: 20px;
      vertical-align: middle;
    }

    .status-text {
      font-size: 14px;
      color: #2aa76c;
      font-weight: 700;
      margin-left: 10px;
    }
  }
}
</style>
